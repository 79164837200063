@media screen and (max-width: 400px) {
    .logo{
        max-width: 70px;
    }
}

@media screen and (min-width: 401px) {
    .logo{
        max-width: 100px;
    }
}
@media screen and (max-width: 1000px){
    .produs-item{
        padding-left: 10px;
        justify-content: left !important;
    }
}

.produs-item{
    display:flex;
    justify-content: center;
}

/* Carousel.css */
.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    flex: 0 0 100%;
    display: none; /* Hide non-active slides */
  }
  
  .carousel-item.active {
    display: flex;
    justify-content: center; /* Show the active slide */
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 2rem;
    z-index: 1;
  }
  
  .carousel-control-prev {
    left: 0;
  }
  
  .carousel-control-next {
    right: 0;
  }